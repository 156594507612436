import gql from "graphql-tag";

export const quotationPricesGql = gql`
  query QuotationPrices {
    quotationPrices {
      ... on QuotationPriceResults {
        results {
          name
          price
          multiplier
        }
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
