import { i18nNumberFormat, i18nTranslate } from "@/plugins/i18n";
import { quotationPricesGql } from "@/api/quotations/quotationPrices";
import {
  QuotationPrices,
  QuotationPrices_quotationPrices_QuotationPriceResults,
} from "@/api/quotations/__generated__/QuotationPrices";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { Ref, computed } from "vue";
import { Quotation_quotation_Quotation } from "@/api/quotations/__generated__/Quotation";

const PricingVariableNames = {
  ORDER_VIDEO_BASE_PRICE: "ORDER_VIDEO_BASE_PRICE",
  ORDER_VIDEO_BASE_TESTIMONIAL_PRICE: "ORDER_VIDEO_BASE_TESTIMONIAL_PRICE",
  ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE: "ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE",
  ORDER_VIDEO_ADD_ON_LENGTH_PRICE: "ORDER_VIDEO_ADD_ON_LENGTH_PRICE",
  ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE: "ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE",
  ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE: "ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE",
  ORDER_VIDEO_ADD_ON_EXPRESS_PRICE: "ORDER_VIDEO_ADD_ON_EXPRESS_PRICE",
  ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE: "ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE",
  ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE:
    "ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE",
  ORDER_VIDEO_ADD_ON_REVISION_REQUESTS_PRICE:
    "ORDER_VIDEO_ADD_ON_REVISION_REQUESTS_PRICE",
  ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE:
    "ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE",
};

export type PricingVariableName = keyof typeof PricingVariableNames;

export const useQuotationPrices = (options?: {
  /** When passed a quotation object, will use the prices in `pricesJson` field over current prices.
   * Use for invoices/summary view.
   */
  quotationRef?: Ref<Quotation_quotation_Quotation | null>;
}) => {
  const { result: quotationPricesResult, loading: quotationPricesLoading } =
    useQuery<QuotationPrices>(
      quotationPricesGql,
      {},
      { fetchPolicy: "no-cache" }
    );

  const quotationPrices = computed(() => {
    let response =
      parseGqlResponse<QuotationPrices_quotationPrices_QuotationPriceResults>(
        "QuotationPriceResults",
        quotationPricesResult.value
      ).data?.results ?? [];

    // Copy over to remove readonly
    response = structuredClone(response);

    if (options?.quotationRef?.value?.priceMatrix) {
      const priceMatrix = JSON.parse(options?.quotationRef?.value?.priceMatrix);

      for (const key of Object.keys(priceMatrix)) {
        const index = response.findIndex((price) => price?.name === key);

        if (index >= 0 && response[index]) {
          const price = Number(priceMatrix[key]["price"]);
          const multiplier = priceMatrix[key]["multiplier"];

          // eslint-disable-next-line
          // @ts-ignore
          Object.assign(response[index], { price, multiplier });
        }
      }
    }

    return response;
  });

  const getQuotationPrice = (name: PricingVariableName): number => {
    return Number(
      quotationPrices.value.find((price) => price?.name == name)?.price ?? 0
    );
  };

  const getQuotationMultiplier = (name: PricingVariableName) => {
    return quotationPrices.value.find((price) => price?.name == name)
      ?.multiplier as "video" | "creator" | undefined;
  };

  /** Helper function to get the pricing value with number formatting */
  const getFormattedQuotationPrice = (
    name: PricingVariableName,
    translateString?: string
  ) => {
    if (!translateString) {
      return i18nNumberFormat(getQuotationPrice(name), "currency", "ph");
    }

    return i18nTranslate(translateString, {
      amount: i18nNumberFormat(getQuotationPrice(name), "currency", "ph"),
    });
  };

  return {
    quotationPrices,
    quotationPricesLoading,
    getQuotationPrice,
    getQuotationMultiplier,
    getFormattedQuotationPrice,
  };
};
